import axios from "../../axios-base";

export const clear = () => {
  return function (dispatch, getState) {
    dispatch(clearStart);
    dispatch(loadEmployeeCategories);
  };
};

const errorMessage = (error) => {
  let resError = "Алдаа гарлаа дахин оролдож үзнэ үү";

  if (resError) {
    resError = error.message;
  }

  if (error.response !== undefined && error.response.status !== undefined) {
    resError = error.response.status;
  }
  if (
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data.error !== undefined
  ) {
    resError = error.response.data.error.message;
  }
  return resError;
};

export const clearStart = () => {
  return {
    type: "CLEAR_NEWSCATEGORIES",
  };
};

export const loadEmployeeCategories = () => {
  return function (dispatch, getState) {
    dispatch(loadEmployeeCategoriesStart());
    axios
      .get("employee-categories")
      .then((response) => {
        const result = response.data.data;
        dispatch(loadEmployeeCategoriesSuccess(result));
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(loadEmployeeCategoriesError(resultError));
      });
  };
};
export const loadEmployeeCategoriesStart = () => {
  return {
    type: "LOAD_EMPLOYEECATEGORIES_START",
  };
};

export const loadEmployeeCategoriesSuccess = (result) => {
  return {
    type: "LOAD_EMPLOYEECATEGORIES_SUCCESS",
    categories: result,
  };
};

export const loadEmployeeCategoriesError = (error) => {
  return {
    type: "LOAD_EMPLOYEECATEGORIES_ERROR",
    error,
  };
};

// SINGLE CATEGORY

export const loadEmployeeCategory = (employeeCategoryId) => {
  return function (dispatch, getState) {
    dispatch(loadEmployeeCategoryStart());
    axios
      .get(`employee-categories/${employeeCategoryId}`)
      .then((response) => {
        const loadedEmployeeCategory = response.data.data;
        dispatch(loadEmployeeCategorySuccess(loadedEmployeeCategory));
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(loadEmployeeCategoryError(resultError));
      });
  };
};

export const loadEmployeeCategoryStart = () => {
  return {
    type: "GET_EMPLOYEECATEGORY_START",
  };
};

export const loadEmployeeCategorySuccess = (result) => {
  return {
    type: "GET_EMPLOYEECATEGORY_SUCCESS",
    category: result,
  };
};

export const loadEmployeeCategoryError = (error) => {
  return {
    type: "GET_EMPLOYEECATEGORY_ERROR",
    error,
  };
};

// Change positions
export const changePosition = (data) => {
  return function (dispatch) {
    dispatch(changePositionStart());

    axios
      .post("employee-categories/change", data)
      .then((response) => {
        const result = response.data.data;
        dispatch(changePositionSuccess(result));
        dispatch(loadEmployeeCategories());
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(changePositionError(resultError));
      });
  };
};

export const changePositionStart = (result) => {
  return {
    type: "NEWSCATEGORIES_CHANGE_POSITION_START",
  };
};

export const changePositionSuccess = (data) => {
  return {
    type: "NEWSCATEGORIES_CHANGE_POSITION_SUCCESS",
    menus: data,
  };
};

export const changePositionError = (error) => {
  return {
    type: "NEWSCATEGORIES_CHANGE_POSITION_ERROR",
    error: error,
  };
};

// DELETE CATEGORY

export const deleteEmployeeCategory = (categoryId, data) => {
  return function (dispatch, getState) {
    dispatch(deleteEmployeeCategoryStart());

    axios
      .delete(`employee-categories/${categoryId}`, data)
      .then((response) => {
        const resultCategory = response.data.data;
        dispatch(deleteEmployeeCategorySuccess(resultCategory));
        dispatch(loadEmployeeCategories());
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(deleteEmployeeCategoryError(resultError));
      });
  };
};

export const deleteEmployeeCategoryStart = () => {
  return {
    type: "DELETE_EMPLOYEECATEGORY_START",
  };
};

export const deleteEmployeeCategorySuccess = (result) => {
  return {
    type: "DELETE_EMPLOYEECATEGORY_SUCCESS",
    dlNews: result,
  };
};

export const deleteEmployeeCategoryError = (error) => {
  return {
    type: "DELETE_EMPLOYEECATEGORY_ERROR",
    error,
  };
};

// SAVE CATEGORY

export const saveEmployeeCategory = (category) => {
  return function (dispatch, getState) {
    dispatch(saveEmployeeCategoryStart());
    let data = {
      name: category.name,
      status: category.status,
    };

    if (category.parentId !== null) {
      data = {
        name: category.name,
        parentId: category.parentId,
      };
    }

    data.language = category.language;
    data.status = category.status;

    axios
      .post(`employee-categories`, data)
      .then((response) => {
        const resultCategory = response.data.data;
        dispatch(saveEmployeeCategorySuccess(resultCategory));
        dispatch(loadEmployeeCategories());
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(saveEmployeeCategoryError(resultError));
      });
  };
};

export const saveEmployeeCategoryStart = () => {
  return {
    type: "CREATE_EMPLOYEECATEGORY_START",
  };
};

export const saveEmployeeCategorySuccess = (resultCategory) => {
  return {
    type: "CREATE_EMPLOYEECATEGORY_SUCCESS",
    resultCategory: resultCategory,
  };
};

export const saveEmployeeCategoryError = (error) => {
  return {
    type: "CREATE_EMPLOYEECATEGORY_ERROR",
    error: error,
  };
};

// UPDATE CATEGORY

export const updateEmployeeCategory = (category, id) => {
  return function (dispatch) {
    dispatch(updateEmployeeCategoryStart());
    const data = {
      name: category.name,
    };

    axios
      .put(`employee-categories/${id}`, data)
      .then((response) => {
        const resultCategory = response.data.data;
        dispatch(updateEmployeeCategorySuccess(resultCategory));
        dispatch(loadEmployeeCategories());
        dispatch(loadEmployeeCategory(id));
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(updateEmployeeCategoryError(resultError));
      });
  };
};

export const updateEmployeeCategoryStart = () => {
  return {
    type: "UPDATE_EMPLOYEECATEGORY_START",
  };
};

export const updateEmployeeCategorySuccess = (resultCategory) => {
  return {
    type: "UPDATE_EMPLOYEECATEGORY_SUCCESS",
    resultCategory: resultCategory,
  };
};

export const updateEmployeeCategoryError = (error) => {
  return {
    type: "UPDATE_EMPLOYEECATEGORY_ERROR",
    error: error,
  };
};
