const initialState = {
  loading: false,
  error: null,
  success: null,
  professions: [],
  paginationLast: {},
  excelData: [],
  profession: {},
  //count
  countLoading: false,
  totalCount: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_PROFESSION":
      return {
        ...state,
        error: null,
        success: null,
        professions: [],
        profession: {},
        excelData: [],
        loading: false,
      };

    case "LOAD_PROFESSION_START":
      return {
        ...state,
        loading: true,
        error: null,
        suceess: null,
        professions: [],
      };

    case "LOAD_PROFESSION_SUCCESS":
      return {
        ...state,
        loading: false,
        professions: action.professions,
      };

    case "LOAD_PROFESSION_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        professions: [],
        error: action.error,
      };

    case "LOAD_PAGINATION":
      return {
        ...state,
        paginationLast: action.pagination,
      };

    // EXCEL
    case "GET_PROFESSION_EXCELDATA_START":
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
        excelData: [],
      };

    case "GET_PROFESSION_EXCELDATA_SUCCESS":
      return {
        ...state,
        loading: false,
        excelData: action.excel,
        error: null,
        success: null,
      };

    case "GET_PROFESSION_EXCELDATA_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        error: action.error,
        excelData: [],
      };

    // SAVE
    case "CREATE_PROFESSION_INIT":
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
      };

    case "CREATE_PROFESSION_START":
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };

    case "CREATE_PROFESSION_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        profession: action.profession,
        success: "Амжилттай нэмэгдлээ",
      };
    case "CREATE_PROFESSION_ERROR":
      return {
        ...state,
        loading: false,
        error: action.error,
        success: null,
      };

    case "DELETE_MULT_PROFESSION_START":
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case "DELETE_MULT_PROFESSION_SUCCESS":
      return {
        ...state,
        loading: false,
        success: "Амжилттай устгагдлаа",
        error: null,
      };
    case "DELETE_MULT_PROFESSION_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        error: action.error,
      };

    //GET
    case "GET_PROFESSION_INIT":
      return {
        ...state,
        loading: false,
        success: null,
        error: null,
        profession: {},
      };

    case "GET_PROFESSION_START":
      return {
        ...state,
        loading: true,
        profession: {},
        error: null,
      };

    case "GET_PROFESSION_SUCCESS":
      return {
        ...state,
        loading: false,
        profession: action.profession,
        error: null,
      };

    case "GET_PROFESSION_ERROR":
      return {
        ...state,
        loading: false,
        profession: {},
        error: action.error,
      };
    //UPDATE
    case "UPDATE_PROFESSION_START":
      return {
        ...state,
        success: null,
        loading: true,
        error: null,
      };
    case "UPDATE_PROFESSION_SUCCESS":
      return {
        ...state,
        loading: false,
        success: "Мэдээллийг амжилттай шинэчлэгдлээ",
        error: null,
      };
    case "UPDATE_PROFESSION_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        error: action.error,
      };

    // GET COUNT
    case "GET_COUNT_PROFESSION_START":
      return {
        ...state,
        countLoading: true,
        totalCount: null,
        error: null,
      };
    case "GET_COUNT_PROFESSION_SUCCESS":
      return {
        ...state,
        coutLoading: false,
        totalCount: action.orderCount,
        error: null,
      };
    case "GET_COUNT_PROFESSION_ERROR":
      return {
        ...state,
        countLoading: false,
        totalCount: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
