import axios from "../../axios-base";

const errorBuild = (error) => {
  let resError = "Алдаа гарлаа дахин оролдож үзнэ үү";

  if (resError) {
    resError = error.message;
  }

  if (error.response !== undefined && error.response.status !== undefined) {
    resError = error.response.status;
  }
  if (
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data.error !== undefined
  ) {
    resError = error.response.data.error.message;
  }
  return resError;
};

export const clear = () => {
  return {
    type: "CLEAR_ADMISSION",
  };
};

// SAVE ADMISSION
export const saveAdmissionInit = () => {
  return {
    type: "CREATE_ADMISSION_INIT",
  };
};

export const saveAdmission = (admission) => {
  return function (dispatch, getState) {
    dispatch(saveAdmissionStart());
    axios
      .post(`/admission`, admission)
      .then((response) => {
        const result = response.data;
        dispatch(saveAdmissionSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(saveAdmissionError(resError));
      });
  };
};

export const saveAdmissionStart = () => {
  return {
    type: "CREATE_ADMISSION_START",
  };
};

export const saveAdmissionSuccess = (result) => {
  return {
    type: "CREATE_ADMISSION_SUCCESS",
    admission: result,
  };
};

export const saveAdmissionError = (error) => {
  return {
    type: "CREATE_ADMISSION_ERROR",
    error,
  };
};

// Excel admission
export const getExcelData = (query) => {
  return function (dispatch) {
    dispatch(getExcelDataStart());
    axios
      .get("admission/excel?" + query)
      .then((response) => {
        const data = response.data.data;
        dispatch(getExcelDataSuccess(data));
      })
      .catch((error) => {
        let resError = errorBuild(error);
        dispatch(getExcelDataError(resError));
      });
  };
};

const getExcelDataStart = () => {
  return {
    type: "GET_ADMISSION_EXCELDATA_START",
  };
};

const getExcelDataSuccess = (data) => {
  return {
    type: "GET_ADMISSION_EXCELDATA_SUCCESS",
    excel: data,
  };
};

const getExcelDataError = (error) => {
  return {
    type: "GET_ADMISSION_EXCELDATA_ERROR",
    error,
  };
};

// LOAD ADMISSION

export const loadAdmission = (query = "") => {
  return function (dispatch, getState) {
    dispatch(loadAdmissionStart());
    axios
      .get("admission?" + query)
      .then((response) => {
        const loadAdmission = response.data.data;
        const pagination = response.data.pagination;
        dispatch(loadAdmissionSuccess(loadAdmission));
        dispatch(loadPagination(pagination));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(loadAdmissionError(resError));
      });
  };
};

export const loadAdmissionStart = () => {
  return {
    type: "LOAD_ADMISSION_START",
  };
};

export const loadAdmissionSuccess = (loadAdmission, pagination) => {
  return {
    type: "LOAD_ADMISSION_SUCCESS",
    loadAdmission,
    pagination,
  };
};

export const loadAdmissionError = (error) => {
  return {
    type: "LOAD_ADMISSION_ERROR",
    error,
  };
};

export const loadPagination = (pagination) => {
  return {
    type: "LOAD_PAGINATION",
    pagination,
  };
};

export const deleteMultAdmission = (ids) => {
  return function (dispatch, getState) {
    dispatch(deleteMultStart());
    axios
      .delete("admission/delete", { params: { id: ids } })
      .then((response) => {
        const deleteAdmission = response.data.data;
        dispatch(deleteAdmissionSuccess(deleteAdmission));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(deleteAdmissionError(resError));
      });
  };
};

export const deleteMultStart = () => {
  return {
    type: "DELETE_MULT_ADMISSION_START",
  };
};

export const deleteAdmissionSuccess = (deleteData) => {
  return {
    type: "DELETE_MULT_ADMISSION_SUCCESS",
    deleteAdmission: deleteData,
  };
};

export const deleteAdmissionError = (error) => {
  return {
    type: "DELETE_MULT_ADMISSION_ERROR",
    error,
  };
};

// GET ADMISSION

export const getInit = () => {
  return {
    type: "GET_ADMISSION_INIT",
  };
};

export const getAdmission = (id) => {
  return function (dispatch, getState) {
    dispatch(getAdmissionStart());
    axios
      .get("admission/" + id)
      .then((response) => {
        const admission = response.data.data;
        dispatch(getAdmissionSuccess(admission));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getAdmissionError(resError));
      });
  };
};

export const getAdmissionStart = () => {
  return {
    type: "GET_ADMISSION_START",
  };
};

export const getAdmissionSuccess = (admission) => {
  return {
    type: "GET_ADMISSION_SUCCESS",
    singleAdmission: admission,
  };
};

export const getAdmissionError = (error) => {
  return {
    type: "GET_ADMISSION_ERROR",
    error,
  };
};

//UPDATE ADMISSION

export const updateAdmission = (id, data) => {
  return function (dispatch) {
    dispatch(updateAdmissionStart());
    axios
      .put(`admission/${id}`, data)
      .then((response) => {
        const result = response.data;
        dispatch(updateAdmissionSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(updateAdmissionError(resError));
      });
  };
};

export const updateAdmissionStart = () => {
  return {
    type: "UPDATE_ADMISSION_START",
  };
};

export const updateAdmissionSuccess = (result) => {
  return {
    type: "UPDATE_ADMISSION_SUCCESS",
    updateAdmission: result,
  };
};

export const updateAdmissionError = (error) => {
  return {
    type: "UPDATE_ADMISSION_ERROR",
    error,
  };
};

export const getCountAdmission = () => {
  return function (dispatch) {
    dispatch(getCountAdmissionStart());

    axios
      .get(`admission/count`)
      .then((response) => {
        const result = response.data.data;
        dispatch(getCountAdmissionSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getCountAdmissionError(resError));
      });
  };
};

export const getCountAdmissionStart = () => {
  return {
    type: "GET_COUNT_ADMISSION_START",
  };
};

export const getCountAdmissionSuccess = (result) => {
  return {
    type: "GET_COUNT_ADMISSION_SUCCESS",
    orderCount: result,
  };
};

export const getCountAdmissionError = (error) => {
  return {
    type: "GET_COUNT_ADMISSION_ERROR",
    error,
  };
};
