import axios from "../../axios-base";

const errorBuild = (error) => {
  let resError = "Алдаа гарлаа дахин оролдож үзнэ үү";

  if (resError) {
    resError = error.message;
  }

  if (error.response !== undefined && error.response.status !== undefined) {
    resError = error.response.status;
  }
  if (
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data.error !== undefined
  ) {
    resError = error.response.data.error.message;
  }
  return resError;
};

export const clear = () => {
  return {
    type: "CLEAR_PROFESSION",
  };
};

// SAVE PROFESSION
export const saveProfessionInit = () => {
  return {
    type: "CREATE_PROFESSION_INIT",
  };
};

export const saveProfession = (data) => {
  return function (dispatch, getState) {
    dispatch(saveProfessionStart());
    axios
      .post(`/professions`, data)
      .then((response) => {
        const result = response.data;
        dispatch(saveProfessionSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(saveProfessionError(resError));
      });
  };
};

export const saveProfessionStart = () => {
  return {
    type: "CREATE_PROFESSION_START",
  };
};

export const saveProfessionSuccess = (result) => {
  return {
    type: "CREATE_PROFESSION_SUCCESS",
    profession: result,
  };
};

export const saveProfessionError = (error) => {
  return {
    type: "CREATE_PROFESSION_ERROR",
    error,
  };
};

// Excel profession
export const getExcelData = (query) => {
  return function (dispatch) {
    dispatch(getExcelDataStart());
    axios
      .get("professions/excel?" + query)
      .then((response) => {
        const data = response.data.data;
        dispatch(getExcelDataSuccess(data));
      })
      .catch((error) => {
        let resError = errorBuild(error);
        dispatch(getExcelDataError(resError));
      });
  };
};

const getExcelDataStart = () => {
  return {
    type: "GET_PROFESSION_EXCELDATA_START",
  };
};

const getExcelDataSuccess = (data) => {
  return {
    type: "GET_PROFESSION_EXCELDATA_SUCCESS",
    excel: data,
  };
};

const getExcelDataError = (error) => {
  return {
    type: "GET_PROFESSION_EXCELDATA_ERROR",
    error,
  };
};

// LOAD PROFESSION

export const loadProfession = (query = "") => {
  return function (dispatch, getState) {
    dispatch(loadProfessionStart());
    axios
      .get("professions?" + query)
      .then((response) => {
        const loadProfession = response.data.data;
        const pagination = response.data.pagination;
        dispatch(loadProfessionSuccess(loadProfession));
        dispatch(loadPagination(pagination));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(loadProfessionError(resError));
      });
  };
};

export const loadProfessionStart = () => {
  return {
    type: "LOAD_PROFESSION_START",
  };
};

export const loadProfessionSuccess = (professions, pagination) => {
  return {
    type: "LOAD_PROFESSION_SUCCESS",
    professions,
    pagination,
  };
};

export const loadProfessionError = (error) => {
  return {
    type: "LOAD_PROFESSION_ERROR",
    error,
  };
};

export const loadPagination = (pagination) => {
  return {
    type: "LOAD_PAGINATION",
    pagination,
  };
};

export const deleteMultProfession = (ids) => {
  return function (dispatch, getState) {
    dispatch(deleteMultStart());
    axios
      .delete("professions/delete", { params: { id: ids } })
      .then((response) => {
        const deleteProfession = response.data.data;
        dispatch(deleteProfessionSuccess(deleteProfession));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(deleteProfessionError(resError));
      });
  };
};

export const deleteMultStart = () => {
  return {
    type: "DELETE_MULT_PROFESSION_START",
  };
};

export const deleteProfessionSuccess = (deleteData) => {
  return {
    type: "DELETE_MULT_PROFESSION_SUCCESS",
    deleteProfession: deleteData,
  };
};

export const deleteProfessionError = (error) => {
  return {
    type: "DELETE_MULT_PROFESSION_ERROR",
    error,
  };
};

// GET PROFESSION

export const getInit = () => {
  return {
    type: "GET_PROFESSION_INIT",
  };
};

export const getProfession = (id) => {
  return function (dispatch, getState) {
    dispatch(getProfessionStart());
    axios
      .get("professions/" + id)
      .then((response) => {
        const profession = response.data.data;
        dispatch(getProfessionSuccess(profession));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getProfessionError(resError));
      });
  };
};

export const getProfessionStart = () => {
  return {
    type: "GET_PROFESSION_START",
  };
};

export const getProfessionSuccess = (profession) => {
  return {
    type: "GET_PROFESSION_SUCCESS",
    profession,
  };
};

export const getProfessionError = (error) => {
  return {
    type: "GET_PROFESSION_ERROR",
    error,
  };
};

//UPDATE PROFESSION

export const updateProfession = (id, data) => {
  return function (dispatch) {
    dispatch(updateProfessionStart());
    axios
      .put(`professions/${id}`, data)
      .then((response) => {
        const result = response.data;
        dispatch(updateProfessionSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(updateProfessionError(resError));
      });
  };
};

export const updateProfessionStart = () => {
  return {
    type: "UPDATE_PROFESSION_START",
  };
};

export const updateProfessionSuccess = (result) => {
  return {
    type: "UPDATE_PROFESSION_SUCCESS",
    updateProfession: result,
  };
};

export const updateProfessionError = (error) => {
  return {
    type: "UPDATE_PROFESSION_ERROR",
    error,
  };
};

export const getCountProfession = () => {
  return function (dispatch) {
    dispatch(getCountProfessionStart());

    axios
      .get(`professions/count`)
      .then((response) => {
        const result = response.data.data;
        dispatch(getCountProfessionSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getCountProfessionError(resError));
      });
  };
};

export const getCountProfessionStart = () => {
  return {
    type: "GET_COUNT_PROFESSION_START",
  };
};

export const getCountProfessionSuccess = (result) => {
  return {
    type: "GET_COUNT_PROFESSION_SUCCESS",
    orderCount: result,
  };
};

export const getCountProfessionError = (error) => {
  return {
    type: "GET_COUNT_PROFESSION_ERROR",
    error,
  };
};
