import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Switch,
  Upload,
  message,
  Modal,
  Tree,
  Select,
} from "antd";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";

//Components
import PageTitle from "../../../Components/PageTitle";
import { InboxOutlined } from "@ant-design/icons";
import Loader from "../../../Components/Generals/Loader";

//Actions
import { tinymceAddPhoto } from "../../../redux/actions/imageActions";
import { loadProfession } from "../../../redux/actions/professionsActions";
import * as actions from "../../../redux/actions/admissionActions";

// Lib
import base from "../../../base";
import axios from "../../../axios-base";
import { toastControl } from "src/lib/toasControl";
import { convertFromdata } from "../../../lib/handleFunction";

const requiredRule = {
  required: true,
  message: "Тус талбарыг заавал бөглөнө үү",
};

const { Dragger } = Upload;

const Add = (props) => {
  const [form] = Form.useForm();
  const [logo, setLogo] = useState({});
  const [linkInput, setInput] = useState({
    name: "",
    link: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [links, setLinks] = useState([]);
  const [progress, setProgress] = useState(0);

  const [loading, setLoading] = useState({
    visible: false,
    message: "",
  });

  // Modal functions
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (linkInput.name && linkInput.link) {
      setLinks((bl) => [...bl, { name: linkInput.name, link: linkInput.link }]);
      setInput({ name: "", link: "" });
      setIsModalOpen(false);
    } else {
      toastControl("error", "Талбаруудыг гүйцэт бөглөнө үү");
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // FUNCTIONS
  const init = () => {
    props.getAdmission(props.match.params.id);
    props.loadProfession(`limit=1000`);
    setInput({ name: "", link: "" });
  };

  const clear = () => {
    props.clear();
    setInput({ name: "", link: "" });
    form.resetFields();
    setLogo({});
    setLoading(false);
  };

  // -- TREE FUNCTIONS

  const handleChange = (event) => {
    form.setFieldsValue({ about: event });
  };

  const handleEdit = (values, status = null) => {
    if (!values.status) values.status = true;
    if (status == "draft") values.status = false;
    if (logo && logo.name) values.avatar = logo.name;
    else values.avatar = "";

    const data = {
      ...values,
    };

    const sendData = convertFromdata(data);
    props.updateAdmission(props.match.params.id, sendData);
  };

  const handleRemove = (stType, file) => {
    let index;

    if (stType === "logo") setLogo({});

    axios
      .delete("/imgupload", { data: { file: file.name } })
      .then((succ) => {
        toastControl("success", "Амжилттай файл устгагдлаа");
      })
      .catch((error) =>
        toastControl("error", "Файл устгах явцад алдаа гарлаа")
      );
  };

  // CONFIGS

  const uploadImage = async (options, type) => {
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    fmData.append("file", file);
    try {
      const res = await axios.post("/imgupload", fmData, config);
      const img = {
        name: res.data.data,
        url: `${base.cdnUrl}${res.data.data}`,
      };
      if (type === "logo") setLogo(img);

      onSuccess("Ok");
      message.success(res.data.data + " Хуулагдлаа");
      return img;
    } catch (err) {
      toastControl("error", err);
      onError({ err });
      return false;
    }
  };

  const logoOptions = {
    onRemove: (file) => handleRemove("logo", file),
    fileList: logo && logo.name && [logo],
    customRequest: (options) => uploadImage(options, "logo"),
    accept: "image/*",
    name: "avatar",
    listType: "picture",
    maxCount: 1,
  };

  // USEEFFECT
  useEffect(() => {
    init();
    return () => clear();
  }, []);

  // Ямар нэгэн алдаа эсвэл амжилттай үйлдэл хийгдвэл энд useEffect барьж аваад TOAST харуулна
  useEffect(() => {
    toastControl("error", props.error);
  }, [props.error]);

  useEffect(() => {
    if (props.success) {
      toastControl("success", props.success);
      setTimeout(() => props.history.replace("/admissions"), 2000);
    }
  }, [props.success]);

  useEffect(() => {
    if (props.admission) {
      form.setFieldsValue({ ...props.admission });
      props.admission.avatar &&
        setLogo({
          name: props.admission.avatar,
          url: `${base.cdnUrl}${props.admission.avatar}`,
        });
    }
  }, [props.admission]);

  return (
    <>
      <div className="content-wrapper">
        <PageTitle name="Бүртгэл нэмэх" />
        <div className="page-sub-menu"></div>
        <div className="content">
          <Loader show={loading.visible}> {loading.message} </Loader>
          <div className="container-fluid">
            <Form layout="vertical" form={form}>
              <div className="row">
                <div className="col-8">
                  <div className="card card-primary">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <Form.Item
                            name="types"
                            label="Хөтөлбөр"
                            rules={[requiredRule]}
                          >
                            <Select
                              style={{ width: "100%" }}
                              defaultValue="school"
                              options={[
                                {
                                  value: "university",
                                  label: "Бакалавр",
                                },
                                {
                                  value: "master",
                                  label: "Магистр",
                                },
                                {
                                  value: "school",
                                  label: "Бүрэн дунд",
                                },
                              ]}
                            />
                          </Form.Item>
                        </div>

                        <div className="col-6">
                          <Form.Item
                            name="program"
                            label="Хөтөлбөр"
                            rules={[requiredRule]}
                          >
                            <Select
                              mode="multiple"
                              style={{ width: "100%" }}
                              placeholder="Хөтөлбөр сонгоно уу"
                              options={props.professions.map((el) => ({
                                label: el.name,
                                value: el._id,
                              }))}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-6">
                          <Form.Item name="urgiinOvog" label="Ургийн овог">
                            <Input placeholder="Ургийн овог" />
                          </Form.Item>
                        </div>
                        <div className="col-6">
                          <Form.Item
                            name="lastName"
                            label="Эцэг эхийн нэр"
                            rules={[requiredRule]}
                          >
                            <Input placeholder="Эцэг эхийн нэрийг оруулна уу" />
                          </Form.Item>
                        </div>
                        <div className="col-6">
                          <Form.Item
                            name="fristName"
                            label="Өөрийн нэр"
                            rules={[requiredRule]}
                          >
                            <Input placeholder="Өөрийн нэрийг оруулна уу" />
                          </Form.Item>
                        </div>
                        <div className="col-6">
                          <Form.Item
                            name="register"
                            label="Регистрийн дугаар"
                            rules={[requiredRule]}
                          >
                            <Input placeholder="Регистрийн дугаараа оруулна уу" />
                          </Form.Item>
                        </div>
                        <div className="col-6">
                          <Form.Item
                            name="birth"
                            label="Төрсөн огноо"
                            rules={[requiredRule]}
                          >
                            <Input placeholder="Төрсөн огноо оруулна уу" />
                          </Form.Item>
                        </div>
                        <div className="col-6">
                          <Form.Item
                            name="gender"
                            label="Хүйс"
                            rules={[requiredRule]}
                          >
                            <Select
                              placeholder="Хүйсээ сонгоно уу"
                              options={[
                                {
                                  value: "Эрэгтэй",
                                  label: "Эрэгтэй",
                                },
                                {
                                  value: "Эмэгтэй",
                                  label: "Эмэгтэй",
                                },
                              ]}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-6">
                          <Form.Item
                            name="country"
                            label="Улс"
                            rules={[requiredRule]}
                          >
                            <Input placeholder="Улсаа оруулна уу" />
                          </Form.Item>
                        </div>
                        <div className="col-6">
                          <Form.Item
                            name="province"
                            label="Аймаг"
                            rules={[requiredRule]}
                          >
                            <Input placeholder="Аймагаа оруулна уу" />
                          </Form.Item>
                        </div>
                        <div className="col-6">
                          <Form.Item
                            name="graduatedDate"
                            label="Төгсөн огноо"
                            rules={[requiredRule]}
                          >
                            <Input placeholder="Төгсөн огноо оруулна уу" />
                          </Form.Item>
                        </div>
                        <div className="col-6">
                          <Form.Item
                            name="phone"
                            label="Утасны дугаар"
                            rules={[requiredRule]}
                          >
                            <Input placeholder="Утасны дугаараа оруулна уу" />
                          </Form.Item>
                        </div>
                        <div className="col-6">
                          <Form.Item
                            name="otherPhone"
                            label="Яаралтай холбоо барих дугаар"
                          >
                            <Input placeholder="Яаралтай холбоо барих дугаараа оруулна уу" />
                          </Form.Item>
                        </div>
                        <div className="col-6">
                          <Form.Item name="email" label="Имэйл хаяг">
                            <Input placeholder="Имэйл хаяг оруулна уу" />
                          </Form.Item>
                        </div>

                        <div className="col-6">
                          <Form.Item name="address" label="Оршин суугаа хаяг">
                            <Input placeholder="Оршин суугаа хаягаа оруулна уу" />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="card">
                    <div class="card-header">
                      <h3 class="card-title">ТОХИРГОО</h3>
                    </div>
                    <div className="card-body">
                      <div className="row"></div>
                    </div>
                    <div className="card-footer">
                      <div className="control-bottons">
                        <Button
                          key="submit"
                          htmlType="submit"
                          className="add-button"
                          loading={props.loading}
                          onClick={() => {
                            form
                              .validateFields()
                              .then((values) => {
                                handleEdit(values);
                              })
                              .catch((info) => {
                                // console.log(info);
                              });
                          }}
                        >
                          Хадгалах
                        </Button>

                        <Button onClick={() => props.history.goBack()}>
                          Буцах
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div class="card-header">
                      <h3 class="card-title">Цээж зураг оруулах</h3>
                    </div>
                    <div className="card-body">
                      <Dragger {...logoOptions} className="upload-list-inline">
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Зургаа энэ хэсэг рүү чирч оруулна уу
                        </p>
                        <p className="ant-upload-hint">
                          Нэг болон түүнээс дээш файл хуулах боломжтой
                        </p>
                      </Dragger>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Modal
        title="Холбоос нэмэх"
        open={isModalOpen}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Болих
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Нэмэх
          </Button>,
        ]}
      >
        <div className="input-box">
          <label>Сайтын нэр</label>
          <input
            type="text"
            placeholder="facebook, twitter, website... гэх мэт"
            value={linkInput.name}
            onChange={(e) =>
              setInput((bi) => ({ ...bi, name: e.target.value }))
            }
          />
        </div>
        <div className="input-box">
          <label>Линк</label>
          <input
            type="text"
            placeholder="https://facebook.com/webr ...."
            value={linkInput.link}
            onChange={(e) =>
              setInput((bi) => ({ ...bi, link: e.target.value }))
            }
          />
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    success: state.admissionReducer.success,
    error: state.admissionReducer.error,
    loading: state.admissionReducer.loading,
    professions: state.professionReducer.professions,
    admission: state.admissionReducer.admission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tinymceAddPhoto: (file) => dispatch(tinymceAddPhoto(file)),
    getAdmission: (id) => dispatch(actions.getAdmission(id)),
    updateAdmission: (id, data) => dispatch(actions.updateAdmission(id, data)),
    loadProfession: () => dispatch(loadProfession()),
    clear: () => dispatch(actions.clear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
